import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { classNames } from 'utilities/classNames';

interface Props<OptionType, ValueType> {
  label?: string | null;
  formName?: string;
  required?: boolean;
  autoComplete?: string;
  value?: string;
  small?: boolean;
  disabled?: boolean;
  options: OptionType[];
  bindValue: (option: OptionType) => ValueType;
  bindLabel: (option: OptionType) => string;
  onChange?: (
    e: ChangeEvent<HTMLSelectElement & { value: ValueType }>,
    setSelectedValue?: (v: string) => void,
  ) => void;
  placeholder?: string | null;
  /**
   * If true, only the given options are displayed, otherwise, there is an additional option saying "select an entry" or the placeholder if given
   */
  hideNoneSelect?: boolean;
  className?: string;
  helpText?: string | null;
  inputRef?: React.Ref<HTMLSelectElement>;
}

// Basic Select Menu from https://tailwindui.com/components/application-ui/forms/select-menus#component-79592f355ca6f6e3b081e0158109981c
export function BasicSelectInput<
  OptionType,
  ValueType extends string | number = string,
>({
  label,
  formName,
  required,
  autoComplete,
  value,
  disabled,
  small,
  options,
  bindLabel,
  onChange,
  bindValue,
  placeholder,
  hideNoneSelect,
  className,
  helpText,
  inputRef,
}: Props<OptionType, ValueType>): JSX.Element {
  const [selectedValue, setSelectedValue] = useState(value);
  const { t } = useTranslation('translation', {
    keyPrefix: 'formFields.selectInput',
  });

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div
      className={classNames(
        !small &&
          'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5',
        className,
      )}
    >
      {label && (
        <label
          htmlFor={formName}
          className={
            'block text-sm font-medium text-gray-700 ' +
            (!small ? 'sm:mt-px sm:pt-2' : '')
          }
        >
          {label} {required && '*'}
        </label>
      )}
      <div
        className={classNames(
          label && 'mt-1',
          !small ? 'sm:mt-0 sm:col-span-2' : '',
        )}
      >
        <select
          id={formName}
          name={formName}
          disabled={disabled}
          ref={inputRef}
          required={required}
          autoComplete={autoComplete || undefined}
          value={selectedValue}
          onChange={(
            e: ChangeEvent<HTMLSelectElement & { value: ValueType }>,
          ) => {
            setSelectedValue(e.target.value);
            onChange?.(e, setSelectedValue);
          }}
          className={classNames(
            'block w-full focus:ring-accent-500 focus:border-accent-500 text-sm border-gray-300 rounded-md',
            !small ? ' max-w-lg sm:max-w-xs' : '',
            disabled && 'border-gray-200 text-gray-400',
          )}
        >
          {[
            !hideNoneSelect && (
              <option key={new Date().valueOf()} value={''}>
                {placeholder ?? t('placeholder')}
              </option>
            ),
            ...(options?.map((o, i) => (
              <option key={i} value={bindValue(o)}>
                {bindLabel(o)}
              </option>
            )) ?? []),
          ]}
        </select>
        {helpText && (
          <p className="mt-2 text-sm text-gray-500 font-normal">{helpText}</p>
        )}
      </div>
    </div>
  );
}

BasicSelectInput.defaultProps = {
  required: false,
  value: '',
  autoComplete: '',
  label: '',
};
