import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR, { useSWRConfig } from 'swr';
import { ComboboxInput } from '../Forms/FormFields/ComboboxInput';
import { CreateExaminerDto, Examiner } from '../../typings/backend-types';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { useAppContext } from '../../context/appContext';
import authenticatedPost from '../../data/authenticatedPost';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { classNames } from '../../utilities/classNames';

interface Props {
  defaultValue?: string;
  onChange: (name: string) => void;
  className?: string;
}

export const ExaminerInput: FunctionComponent<Props> = ({
  defaultValue,
  onChange,
  className,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'exams',
  });
  const { organizationId } = useAppContext();
  const { setErrorMessage } = useErrorPopupContext();
  const { mutate } = useSWRConfig();

  const { data: examiners, isValidating } = useSWR<Examiner[]>(
    () => HttpEndpoints.ExaminerEndpoints.getByOrganization(organizationId),
    authenticatedFetcher,
    { fallbackData: [] },
  );

  async function postExaminer(name: string) {
    const dto: CreateExaminerDto = { name };
    try {
      await authenticatedPost(
        HttpEndpoints.ExaminerEndpoints.postExaminer(organizationId),
        dto,
        'POST',
      );
    } catch (e) {
      setErrorMessage(t('couldNotAddExaminer'));
    }
    await mutate(
      HttpEndpoints.ExaminerEndpoints.getByOrganization(organizationId),
    );
    await mutate(
      HttpEndpoints.ExaminerEndpoints.getByOrganization(organizationId, name),
    );
  }

  useEffect(() => {
    if (
      !isValidating &&
      defaultValue &&
      !examiners.find((e) => e.name === defaultValue)
    ) {
      void postExaminer(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, isValidating, examiners]);

  async function createAndSelectExaminer(
    selector: (v: { name: string }) => void,
    name: string,
  ) {
    await postExaminer(name);
    selector({ name });
    onChange(name);
  }

  return (
    <div className={classNames('my-5', className)}>
      <ComboboxInput<{ name: string }>
        required
        label={t('examiner')}
        placeholder={t('attemptForm.examinerPlaceholder')}
        fetchUrl={(query) =>
          HttpEndpoints.ExaminerEndpoints.getByOrganization(
            organizationId,
            query,
          )
        }
        defaultValue={defaultValue}
        onChange={(v) => onChange(v.name)}
        bindValue={(value) => value?.name}
        bindLabel={(value) => value?.name}
        constructFallbackOption={(query) => {
          if (!query) return null;
          if (examiners.some((e) => e.name === query)) return null;
          return {
            name: query,
            onSelect: createAndSelectExaminer,
          };
        }}
      />
    </div>
  );
};
