import React, { FunctionComponent } from 'react';
import { Color } from '../../typings/backend-types';
import { classNames } from 'utilities/classNames';

interface Props {
  color: Color;
  className?: string;
  faint?: boolean;
  peerHoverable?: boolean;
}

export const Tag: FunctionComponent<Props> = ({
  color,
  className,
  faint,
  children,
  peerHoverable,
}) => {
  return (
    <span
      className={classNames(
        'inline-flex px-2.5 py-1 my-1 mr-2 rounded-md items-center select-none',
        className,
        `bg-${color}-100`,
        peerHoverable && `peer-hover:bg-${color}-200`,
        faint ? `text-${color}-500` : `text-${color}-800`,
      )}
    >
      {children}
    </span>
  );
};
