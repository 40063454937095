import { captureException } from '@sentry/nextjs';
import authenticatedPost from '../../data/authenticatedPost';
import { UpdateStudentDto } from '../../typings/backend-types';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { mutate } from 'swr';

export async function archiveStudent(
  studentId: string,
  organizationId: string,
  showError: () => void,
  onSuccess?: () => void,
) {
  try {
    const updateDto: UpdateStudentDto = {
      archived: true,
    };
    await authenticatedPost(
      HttpEndpoints.StudentEndpoints.patchStudent(studentId, organizationId),
      updateDto,
      'PATCH',
    );
    onSuccess?.();
  } catch (e) {
    showError();
    captureException(e);
  }
  void mutate(HttpEndpoints.StudentEndpoints.getStudentById(studentId));
}
