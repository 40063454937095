import { t } from 'i18next';
import { archiveStudent } from './archive-student';
import { ConfirmPopupInfo } from '../../context/confirmPopupContext';
import { Student } from '../../typings/backend-types';
import { Notification } from '../../context/notificationContext';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

export async function suggestArchivingPassedStudent(
  student: Student,
  organizationId: string,
  confirm: (n: ConfirmPopupInfo) => Promise<void>,
  setErrorMessage: (n: string) => void,
  setNotification: (n: Notification) => void,
): Promise<void> {
  if (student.archived) return;
  try {
    await confirm({
      title: t('students.suggestArchivingPassedStudent.title'),
      message: t('students.suggestArchivingPassedStudent.message'),
      okText: t('students.suggestArchivingPassedStudent.okText'),
    });
    void archiveStudent(
      student.id,
      organizationId,
      () => {
        setErrorMessage(t('students.suggestArchivingPassedStudent.error'));
      },
      () =>
        setNotification({
          title: t('students.suggestArchivingPassedStudent.success'),
          icon: <CheckCircleIcon className="w-5 h-5 text-green-500" />,
        }),
    );
  } catch (e) {
    return;
  }
}
