import { useEffect, useState } from 'react';
import { classNames } from 'utilities/classNames';

interface Props<T extends { id: string }> {
  label: string;
  formName: string;
  options: T[];
  defaultCheckedId?: string;
  required?: boolean;
  small?: boolean;
  onChange?: (optionId: string) => void;
  className?: string;
  renderOption?: (
    option: T,
    select: () => void,
    isActive: boolean,
    disabled: boolean,
  ) => JSX.Element;
  inlineOptions?: boolean;
  narrow?: boolean;
  disabled?: boolean;
}

/**
 * RadioGroup based on the tailwind component here: https://tailwindui.com/components/application-ui/forms/radio-groups#component-5a30657598f3154f9bc03a680cea7ff4
 * options should have an id, onChange is called with this id
 */
export function RadioList<T extends { id: string; title: string }>({
  label,
  formName,
  options,
  onChange,
  required,
  defaultCheckedId,
  small,
  className,
  renderOption,
  inlineOptions,
  narrow,
  disabled = false,
}: Props<T>): JSX.Element {
  const [selected, setSelected] = useState<string | undefined>(
    defaultCheckedId,
  );

  useEffect(() => {
    setSelected(defaultCheckedId);
  }, [defaultCheckedId]);

  useEffect(() => {
    selected && onChange?.(selected);
  }, [selected, onChange]);

  return (
    <div
      className={classNames(
        !small &&
          'sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200',
        !small && !narrow && 'sm:pt-5',
        !small && narrow && 'sm:pt-2',
        className,
      )}
    >
      <label
        className={classNames(
          'block text-sm font-medium text-gray-700 ',
          !small && 'sm:mt-px sm:pt-2',
        )}
      >
        {label} {required && '*'}
      </label>
      <fieldset className="mt-3">
        <legend className="sr-only">{label}</legend>
        <div
          className={classNames(inlineOptions ? 'flex flex-wrap' : 'space-y-4')}
        >
          {options.map((option) => (
            <div key={option.id} className="flex items-center">
              <input
                id={option.id}
                name={formName}
                type="radio"
                className={classNames(
                  renderOption
                    ? 'w-0 opacity-0'
                    : 'focus:ring-accent-500 h-4 w-4 text-accent-600 border-gray-300',
                )}
                required={required}
                disabled={disabled}
                value={option.id}
                checked={option.id === selected}
                onChange={(e) => {
                  setSelected(e.target.value);
                }}
              />

              <label
                htmlFor={option.id}
                className={classNames(
                  inlineOptions ? 'mr-1' : 'ml-3',
                  'block text-sm font-medium text-gray-700',
                )}
              >
                {renderOption
                  ? renderOption(
                      option,
                      () => !disabled && setSelected(option.id),
                      option.id === selected,
                      disabled,
                    )
                  : option.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
