import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { FunctionComponent, useState } from 'react';
import { classNames } from 'utilities/classNames';
import { assert } from 'utilities/errors';

interface ListProps {
  className?: string;
}

/**
 * Stacked List Wrapper based on the tailwind component https://tailwindui.com/components/application-ui/lists/stacked-lists#component-371ccbef96ba57325d261f1b74afdd41
 */
export const StackedList: FunctionComponent<ListProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={classNames('bg-white overflow-hidden font-normal', className)}
    >
      <ul role="list" className="divide-y divide-gray-200">
        {children}
      </ul>
    </div>
  );
};

interface DropdownSectionProps {
  title: string | JSX.Element;
  defaultOpen?: boolean;
  className?: string;
  rightIcon?: JSX.Element;
  icon?: JSX.Element;
}

/**
 * Stacked List Wrapper based on the tailwind component https://tailwindui.com/components/application-ui/lists/stacked-lists#component-371ccbef96ba57325d261f1b74afdd41
 */
export const StackedListDropdownSection: FunctionComponent<
  DropdownSectionProps
> = ({ title, children, defaultOpen, className, rightIcon, icon }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen ?? false);

  return (
    <>
      <StackedListItem
        className={classNames('bg-gray-50', className)}
        onClick={icon ? undefined : () => setIsOpen((o) => !o)}
        hideOnClickIcon
        rightIcon={rightIcon}
        icon={
          icon ?? (
            <div className="justify-self-end">
              {isOpen ? (
                <ChevronUpIcon className="w-5 h-5 text-gray-500" />
              ) : (
                <ChevronDownIcon className="w-5 h-5 text-gray-500" />
              )}
            </div>
          )
        }
      >
        <span>{title}</span>
      </StackedListItem>
      {isOpen && (
        <li className={'bg-white overflow-hidden font-normal'}>
          <ul role="list" className="divide-y divide-gray-200 border-b pl-2">
            {children}
          </ul>
        </li>
      )}
    </>
  );
};

interface ListItemProps {
  onClick?: () => void;
  href?: string;
  icon?: JSX.Element;
  rightIcon?: JSX.Element;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  hideOnClickIcon?: boolean;
  paddingY?: number;
}

/**
 * Wrapper for items of a stacked list
 * Includes a ChevronRightIcon and spreads the children evenly along the width of the ListItem
 */
export const StackedListItem: FunctionComponent<ListItemProps> = ({
  onClick,
  href,
  icon,
  rightIcon,
  children,
  className,
  hideOnClickIcon,
  paddingY,
}) => {
  assert(
    !(href && onClick),
    "Can't use href and on click handler simultaneously",
  );
  let childrenNumber = 1;
  if (Array.isArray(children)) {
    childrenNumber = children.filter(Boolean).length;
  }
  return (
    <li className={classNames(className, 'text-gray-600')}>
      <a
        onClick={onClick}
        href={href}
        className={classNames(
          'block hover:bg-accent-50',
          onClick && 'cursor-pointer',
        )}
      >
        <div
          className={classNames(
            'flex items-center self-center',
            `py-${paddingY ?? 5}`,
            icon ? 'px-2' : 'sm:px-2',
          )}
        >
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">{icon}</div>
            <div
              className={classNames(
                'min-w-0 flex-1 px-2 grid gap-2 md:gap-4 items-center',
                `grid-cols-${childrenNumber}`,
              )}
            >
              {children}
            </div>
          </div>

          {(!hideOnClickIcon || rightIcon) && (
            <div className="self-center mr-1 min-w-[1.25rem]">
              {(onClick || href) && !hideOnClickIcon && (
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              )}
              {rightIcon}
            </div>
          )}
        </div>
      </a>
    </li>
  );
};
