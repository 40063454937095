import { t } from 'i18next';
import { LicenseCategory } from '../../typings/backend-types';

export const licenseCategories = Object.keys(
  LicenseCategory,
) as LicenseCategory[];

export function translateLicenseCategory(category: LicenseCategory): string {
  if (category !== LicenseCategory.None) return category;
  return t('common.licenseCategory.none');
}
