import { Fragment, FunctionComponent, SVGProps } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { BellIcon } from '@heroicons/react/24/solid';

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string | JSX.Element | null;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
}

/**
 * Component based on tailwindUI branded slide over https://tailwindui.com/components/application-ui/overlays/slide-overs#component-c1585c18fdf5c401aa6081095b12f432
 */
export const SlideOverPanel: FunctionComponent<Props> = ({
  open,
  onClose,
  title,
  icon,
  children,
}) => {
  const Icon = icon;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={
          onClose ||
          (() => {
            return;
          })
        }
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 m-5 m">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col bg-white shadow-xl rounded-xl">
                  <div className="bg-accent-100 py-8 px-4 sm:px-6 rounded-t-xl">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-accent-500">
                        <p>
                          {Icon && (
                            <Icon
                              className="h-6 w-6 inline-block mr-3"
                              aria-hidden="true"
                            />
                          )}
                          {title}
                        </p>
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-accent-100 text-accent-500 hover:text-accent-700 focus:outline-none "
                          onClick={onClose}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 py-6 px-4 sm:px-6 overflow-y-scroll">
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

SlideOverPanel.defaultProps = {
  icon: () => (
    <BellIcon className="h-6 w-6 inline-block mr-3" aria-hidden="true" />
  ),
};
